import store from "@/store";
import { checkFunctionUser } from "./utils";
import { jwtDecode } from "jwt-decode";
import Vue from "vue";
import Component from "vue-class-component";

const tokenInfo = jwtDecode<any>(localStorage.getItem("userToken"));

interface MenuItem {
  title: string;
  route?: string;
  icon?: string;
  type?: string;
  class?: string;
  iconType?: string;
  children?: MenuItem[];
  functionIds?: number[];
  role?: string;
}

const mobileMenuItems: MenuItem[] = [
  {
    title: "Accueil",
    route: "dashboard",
    icon: "dashboard",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Tableau de bord",
        class: "mt-1",
        children: [
          {
            title: "Mon agenda",
            route: "my-agenda",
            functionIds: [133],
          },
          {
            title: "Tableau de bord Analytique",
            route: "dashboard-analytique",
            functionIds: [134],
          },
          {
            title: "Tableau de bord Gestion",
            route: "dashboard-gestion",
            functionIds: [135],
          },
          {
            title: "Tableau de bord CRM",
            route: "dashboard-crm",
            functionIds: [136],
          },
          {
            title: "Tableau de bord Immo",
            route: "dashboard-immos",
            functionIds: [137],
          },
          {
            title: "Résultats analytiques",
            route: "analytical-results",
            functionIds: [139],
          },
          {
            title: "Ecritures analytiques",
            route: "analytical-entries",
            functionIds: [140],
          },
        ],
      },
    ],
  },
  {
    title: "Affaires",
    route: "affairs",
    icon: "work_outline",
    iconType: "google",
    children: [
      {
        title: "Toutes mes affaires",
        route: "affairs",
        functionIds: [1],
      },
      {
        title: "Baux",
        route: "leases",
        functionIds: [37],
      },
    ],
  },
  {
    title: "Facturation",
    icon: "description",
    iconType: "google",
    children: [
      {
        title: "Devis",
        route: "quotes",
        functionIds: [75],
      },
      {
        title: "Bons de commande",
        route: "order-form-customers",
        functionIds: [108, 109, 110],
      },
      {
        title: "Factures",
        route: "invoices",
        functionIds: [66, 67],
      },
      {
        title: "Avoirs",
        route: "credits",
        functionIds: [51, 52, 53],
      },
      {
        title: "Retenues de garantie",
        route: "holdbacks",
        functionIds: [61, 62],
      },
      {
        title: "Règlements",
        route: "payments",
        functionIds: [69, 71],
      },
      {
        title: "Produits de vente/stock",
        route: "catalog",
        functionIds: [72],
      },
      {
        title: "Points de collecte",
        route: "waste-collection-points",
        functionIds: [83, 84],
      },
    ],
  },
  {
    title: "Achat",
    icon: "shopping_cart",
    iconType: "google",
    children: [
      {
        title: "Commandes fournisseur",
        route: "order-form-providers",
        functionIds: [24, 25, 26],
      },
      {
        title: "Bons de réception",
        route: "receipt-form-providers",
        functionIds: [30, 31, 32],
      },
      {
        title: "Produits fournisseur",
        route: "product-provider",
        functionIds: [74],
      },
      {
        title: "Factures fournisseur",
        route: "invoices-provider",
        functionIds: [22, 114],
      },
      {
        title: "Avoirs fournisseur",
        route: "credits-provider",
        functionIds: [17, 115],
      },
      {
        title: "Règlements fournisseur",
        route: "payment-providers",
        functionIds: [113],
      },
      {
        title: "Inventaires",
        route: "inventories",
        functionIds: [19, 20, 21],
      },
      {
        title: "Mouvements de stock",
        route: "stock-movements",
        functionIds: [34, 35, 36],
      },
      {
        title: "Bons de sortie",
        route: "delivery-forms",
        functionIds: [12, 13, 14],
      },
      {
        title: "Points de stockage",
        route: "storage-locations",
        functionIds: [36],
      },
    ],
  },
  {
    title: "Planning",
    icon: "event_note",
    iconType: "google",
    children: [
      {
        type: "divider",
        title:
          store.getters.planningViewsList.length > 1 ? "Planning" : "Planning",
        class: "mt-1",
        children: store.getters.planningViewsList
          .map((e) => {
            return {
              title: e.name,
              name: "planning",
              params: {
                id: e.id,
                title: "Planning : " + e.name,
                tips: "Planning : " + e.name,
              },
              functionIds: [45, 46, 47],
            };
          })
          .concat([
            {
              title: "Créer un nouveau planning",
              route: "newPlanningView",
              functionIds: [45, 46, 47],
            },
          ]),
      },
    ],
  },
  {
    title: "Reporting",
    icon: "pending_actions",
    iconType: "google",
    children: [
      {
        title: "Mes reportings",
        route: "my-reports",
        functionIds: [92],
      },
      {
        title: "Mes missions",
        route: "my-missions",
        functionIds: [93],
      },
      {
        title: "Reportings par collaborateur",
        route: "reports-by-collaborators",
        functionIds: [92],
      },
      {
        title: "Missions par collaborateur",
        route: "missions-by-collaborators",
        functionIds: [93],
      },
      {
        title: "Mes interventions",
        route: "myInterventions",
        functionIds: [121],
      },
      {
        title: "Interventions par collaborateur",
        route: "interventions-by-collaborators",
        functionIds: [121],
      },
    ],
  },
  {
    title: "Annuaire",
    icon: "perm_contact_calendar",
    iconType: "google",
    children: [
      {
        title: "Mes activités commerciales",
        route: "my-activities",
        functionIds: [100],
      },
      {
        title: "Mes relances",
        route: "my-reminders",
        functionIds: [100],
      },
      {
        title: "Activités co. par collaborateur",
        route: "activities-by-collaborators",
        functionIds: [100],
      },
      {
        title: "Relances par collaborateur",
        route: "reminders-by-collaborators",
        functionIds: [100],
      },
      ...store.getters.companiesTypesList.map((e) => {
        return {
          title: e.label,
          path: "directory/companies/" + e.label.toLowerCase() + "/" + e.id,
          params: {
            name: e.label.toLowerCase(),
            id: e.id,
            pageTitle: e.label,
            title: e.label,
            tips: "Liste des " + e.label + "s",
          },
          functionIds: [6],
        };
      }),
      {
        title: "Contacts",
        route: "contacts",
        functionIds: [8],
      },
    ],
  },
  {
    title: "Entreprise",
    icon: "people_alt",
    iconType: "google",
    children: [
      {
        title: "Collaborateurs",
        route: "collaborators",
        functionIds: [5],
      },
      {
        title: "Utilisateurs",
        route: "users",
        functionIds: [89],
      },
    ],
  },
  {
    title: "Paramètres",
    icon: "settings",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Administration",
        class: "mt-1",
        children: [
          {
            title: "Environnements",
            route: "adminWorkspaces",
            functionIds: [],
            role: "Admin",
          },
          {
            title: "Paramètres des modules",
            route: "new-module-parameter",
            functionIds: [],
            role: "Admin",
          },
        ],
      },
      {
        type: "divider",
        title: "Paramètres généraux",
        class: "mt-1",
        children: [
          {
            title: "Codes analytiques",
            route: "analyticsSettings",
            functionIds: [94],
          },
          {
            title: "Catégories d'affaires",
            route: "affairCategories",
            functionIds: [2],
          },
          {
            title: "Types de contrats",
            route: "contracts",
            functionIds: [105],
          },
          {
            title: "Métiers",
            route: "jobs",
            functionIds: [106],
          },
          {
            title: "Tags personnalisés",
            route: "tags",
            functionIds: [],
          },
        ],
      },
      {
        type: "divider",
        title: "Achat",
        class: "mt-1",
        children: [
          {
            title: `Catégories d'achat`,
            route: "purchaseCategories",
            functionIds: [29],
          },
        ],
      },
      {
        type: "divider",
        title: "Baux",
        class: "mt-1",
        children: [
          {
            title: "Périodicités",
            route: "periodicities",
            functionIds: [39],
          },
          {
            title: "Types de baux",
            route: "lease-types",
            functionIds: [38],
          },
          {
            title: "Types de biens",
            route: "property-types",
            functionIds: [41],
          },
        ],
      },
      {
        type: "divider",
        title: "CRM",
        class: "mt-1",
        children: [
          {
            title: "Objectifs par collaborateur",
            route: "collaboratorGoals",
            functionIds: [120],
          },
          {
            title: "Indicateurs sur sociétés",
            route: "companyIndicators",
            functionIds: [41],
          },
          {
            title: "Canaux de contact",
            route: "activityChanelTypes",
            functionIds: [101],
          },
          {
            title: "Types de résultat de contact",
            route: "activityResultTypes",
            functionIds: [102],
          },
          {
            title: "Origines de contact",
            route: "activitySourceTypes",
            functionIds: [103],
          },
        ],
      },
      {
        type: "divider",
        title: "Planning",
        class: "mt-1",
        children: [
          {
            title: "Vues de planning",
            route: "planningViews",
            functionIds: [47],
          },
          {
            title: "Types d'évènements",
            route: "eventTypes",
            functionIds: [44],
          },
        ],
      },
      {
        type: "divider",
        title: "Vente",
        class: "mt-1",
        children: [
          {
            title: "Catégories de produits et services",
            route: "productCategories",
            functionIds: [73],
          },
          {
            title: "Catégories de vente",
            route: "saleCategories",
            functionIds: [80],
          },
        ],
      },
      {
        type: "divider",
        title: "Reporting",
        class: "mt-1",
        children: [
          {
            title: "Plan des missions",
            route: "missionTypes",
            functionIds: [93],
          },
          {
            title: "Variables de paie",
            route: "payrollEvents",
            functionIds: [96],
          },
          {
            title: "Types d'indemnités de paie",
            route: "payAllowanceTypes",
            functionIds: [95],
          },
        ],
      },
      {
        type: "divider",
        title: "Services additionnels",
        class: "mt-1",
        children: [
          {
            title: "Historique des SMS",
            route: "sms",
            functionIds: [48],
          },
        ],
      },
      {
        path: "/documentation",
        name: "documentation",
        beforeEnter() {
          window.open("https://doc.orisis.fr/", "_blank");
        },
      },
    ],
  },
];

const rightMenuItems: MenuItem[] = [
  {
    title: "Paramètres",
    icon: "settings",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Administration",
        class: "mt-1",
        children: [
          {
            title: "Environnements",
            route: "adminWorkspaces",
            functionIds: [],
            role: "Admin",
          },
          {
            title: "Nouveau profil",
            route: "new-profile",
            functionIds: [9, 10, 11],
          },
          {
            title: "Paramètres des module",
            route: "new-module-parameter",
            functionIds: [],
            role: "Admin",
          },
        ],
      },
      {
        type: "divider",
        title: "Paramètres généraux",
        class: "mt-1",
        children: [
          {
            title: "Codes analytiques",
            route: "analyticsSettings",
            functionIds: [94],
          },
          {
            title: "Catégories d'affaires",
            route: "affairCategories",
            functionIds: [2],
          },
          {
            title: "Formulaires de champs personnalisés",
            route: "customFieldTemplates",
            functionIds: [97, 98, 99],
          },
          {
            title: "Types de contrats",
            route: "contracts",
            functionIds: [105],
          },
          {
            title: "Métiers",
            route: "jobs",
            functionIds: [106],
          },
          {
            title: "Tags personnalisés",
            route: "tags",
            functionIds: [],
          },
        ],
      },
      {
        type: "divider",
        title: "Achat",
        class: "mt-1",
        children: [
          {
            title: `Catégories d'achat`,
            route: "purchaseCategories",
            functionIds: [29],
          },
        ],
      },
      {
        type: "divider",
        title: "Baux",
        class: "mt-1",
        children: [
          {
            title: "Périodicités",
            route: "periodicities",
            functionIds: [39],
          },
          {
            title: "Types de baux",
            route: "lease-types",
            functionIds: [38],
          },
          {
            title: "Types de biens",
            route: "property-types",
            functionIds: [41],
          },
        ],
      },
      {
        type: "divider",
        title: "CRM",
        class: "mt-1",
        children: [
          {
            title: "Objectifs par collaborateur",
            route: "collaboratorGoals",
            functionIds: [120],
          },
          {
            title: "Indicateurs sur sociétés",
            route: "companyIndicators",
            functionIds: [41],
          },
          {
            title: "Canaux de contact",
            route: "activityChanelTypes",
            functionIds: [101],
          },
          {
            title: "Types de résultat de contact",
            route: "activityResultTypes",
            functionIds: [102],
          },
          {
            title: "Origines de contact",
            route: "activitySourceTypes",
            functionIds: [103],
          },
        ],
      },
      {
        type: "divider",
        title: "Planning",
        class: "mt-1",
        children: [
          {
            title: "Vues de planning",
            route: "planningViews",
            functionIds: [47],
          },
          {
            title: "Types d'évènements",
            route: "eventTypes",
            functionIds: [44],
          },
        ],
      },
      {
        type: "divider",
        title: "Vente",
        class: "mt-1",
        children: [
          {
            title: "Catégories de produits et services",
            route: "productCategories",
            functionIds: [73],
          },
          {
            title: "Catégories de vente",
            route: "saleCategories",
            functionIds: [80],
          },
        ],
      },

      {
        type: "divider",
        title: "Reporting",
        class: "mt-1",
        children: [
          {
            title: "Plan des missions",
            route: "missionTypes",
            functionIds: [93],
          },
          {
            title: "Variables de paie",
            route: "payrollEvents",
            functionIds: [96],
          },
          {
            title: "Types d'indemnités de paie",
            route: "payAllowanceTypes",
            functionIds: [95],
          },
        ],
      },
      {
        type: "divider",
        title: "Services additionnels",
        class: "mt-1",
        children: [
          {
            title: "Historique des SMS",
            route: "sms",
            functionIds: [48],
          },
        ],
      },
    ],
  },
  {
    title: "Documentation",
    icon: "help_outline",
    iconType: "google",
    route: "documentation",
  },
];

const menuItems: MenuItem[] = [
  {
    title: "Accueil",
    route: "dashboard",
    icon: "dashboard",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Tableau de bord",
        class: "mt-1",
        children: [
          {
            title: "Mon agenda",
            route: "my-agenda",
            functionIds: [133],
          },
          {
            title: "Tableau de bord Analytique",
            route: "dashboard-analytique",
            functionIds: [134],
          },
          {
            title: "Tableau de bord Gestion",
            route: "dashboard-gestion",
            functionIds: [135],
          },
          {
            title: "Tableau de bord CRM",
            route: "dashboard-crm",
            functionIds: [136],
          },
          {
            title: "Tableau de bord Immo",
            route: "dashboard-immo",
            functionIds: [137],
          },
          {
            title: "Résultats analytiques",
            route: "analytical-results",
            functionIds: [139],
          },
          {
            title: "Ecritures analytiques",
            route: "analytical-entries",
            functionIds: [140],
          },
        ],
      },
    ],
  },
  {
    title: "Affaires",
    route: "affairs",
    icon: "work_outline",
    iconType: "google",
    children: [
      {
        title: "Toutes mes affaires",
        route: "affairs",
        functionIds: [1],
      },
      {
        type: "divider",
        title: "Par catégorie",
        class: "mt-1",
        children: [
          {
            title: "Baux",
            route: "leases",
            functionIds: [37],
          },
        ],
      },
    ],
  },
  {
    title: "Facturation",
    icon: "description",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Devis",
        class: "mt-1",
        children: [
          {
            title: "Devis",
            route: "quotes",
            functionIds: [75],
          },
        ],
      },
      {
        type: "divider",
        title: "Commandes",
        class: "mt-1",
        children: [
          {
            title: "Commandes client",
            route: "order-form-customers",
            functionIds: [108, 109, 110],
          },
          {
            title: "Factures",
            route: "invoices",
            functionIds: [66, 67],
          },
          {
            title: "Avoirs",
            route: "credits",
            functionIds: [51, 52, 53],
          },
        ],
      },

      {
        type: "divider",
        title: "Retenues de garantie",
        class: "mt-1",
        children: [
          {
            title: "Retenues de garantie",
            route: "holdbacks",
            functionIds: [61, 62],
          },
        ],
      },
      {
        type: "divider",
        title: "Règlements",
        class: "mt-1",
        children: [
          {
            title: "Règlements",
            route: "payments",
            functionIds: [69, 71],
          },
        ],
      },
      {
        type: "divider",
        title: "Catalogue",
        class: "mt-1",
        children: [
          {
            title: "Produits de vente/stock",
            route: "catalog",
            functionIds: [72],
          },
          {
            title: "Points de collecte",
            route: "waste-collection-points",
            functionIds: [83, 84],
          },
        ],
      },
    ],
  },
  {
    title: "Achat",
    icon: "shopping_cart",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Commandes",
        class: "mt-1",
        children: [
          {
            title: "Commandes fournisseur",
            route: "order-form-providers",
            functionIds: [24, 25, 26],
          },
          {
            title: "Bons de réception",
            route: "receipt-form-providers",
            functionIds: [30, 31, 32],
          },
          {
            title: "Produits fournisseur",
            route: "product-provider",
            functionIds: [74],
          },
        ],
      },
      {
        type: "divider",
        title: "Facturation",
        class: "mt-1",
        children: [
          {
            title: "Factures fournisseur",
            route: "invoices-provider",
            functionIds: [22, 114],
          },
          {
            title: "Avoirs fournisseur",
            route: "credits-provider",
            functionIds: [17, 115],
          },
        ],
      },
      {
        type: "divider",
        title: "Règlements",
        class: "mt-1",
        children: [
          {
            title: "Règlements fournisseur",
            route: "payment-providers",
            functionIds: [113],
          },
        ],
      },
      {
        type: "divider",
        title: "Stocks",
        class: "mt-1",
        children: [
          {
            title: "Inventaires",
            route: "inventories",
            functionIds: [19, 20, 21],
          },
          {
            title: "Mouvements de stock",
            route: "stock-movements",
            functionIds: [34, 35, 36],
          },
          {
            title: "Bons de sortie",
            route: "delivery-forms",
            functionIds: [12, 13, 14],
          },
          {
            title: "Points de stockage",
            route: "storage-locations",
            functionIds: [36],
          },
        ],
      },
    ],
  },
  {
    title: "Planning",
    icon: "event_note",
    iconType: "google",
    children: [
      {
        type: "divider",
        title:
          store.getters.planningViewsList.length > 1 ? "Planning" : "Planning",
        class: "mt-1",
        children: store.getters.planningViewsList
          .map((e) => {
            return {
              title: e.name,
              name: "planning",
              params: {
                id: e.id,
                title: "Planning : " + e.name,
                tips: "Planning : " + e.name,
              },
              functionIds: [45, 46, 47],
            };
          })
          .concat([
            {
              title: "Créer un nouveau planning",
              route: "newPlanningView",
              functionIds: [45, 46, 47],
            },
          ]),
      },
    ],
  },
  {
    title: "Reporting",
    icon: "pending_actions",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Reporting",
        class: "mt-1",
        children: [
          {
            title: "Mes reportings",
            route: "my-reports",
            functionIds: [92],
          },
          {
            title: "Mes missions",
            route: "my-missions",
            functionIds: [93],
          },
          {
            title: "Reportings par collaborateur",
            route: "reports-by-collaborators",
            functionIds: [92],
          },
          {
            title: "Missions par collaborateur",
            route: "missions-by-collaborators",
            functionIds: [93],
          },
        ],
      },
      {
        type: "divider",
        title: "Interventions",
        class: "mt-1",
        children: [
          {
            title: "Mes interventions",
            route: "myInterventions",
            functionIds: [121],
          },
          {
            title: "Interventions par collaborateur",
            route: "interventions-by-collaborators",
            functionIds: [121],
          },
        ],
      },
    ],
  },
  {
    title: "Annuaire",
    icon: "perm_contact_calendar",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "CRM",
        class: "mt-1",
        children: [
          {
            title: "Mes activités commerciales",
            route: "my-activities",
            functionIds: [100],
          },
          {
            title: "Mes relances",
            route: "my-reminders",
            functionIds: [100],
          },
          {
            title: "Activités co. par collaborateur",
            route: "activities-by-collaborators",
            functionIds: [100],
          },
          {
            title: "Relances par collaborateur",
            route: "reminders-by-collaborators",
            functionIds: [100],
          },
        ],
      },
      {
        type: "divider",
        title: "Annuaire",
        class: "mt-1",
        children: store.getters.companiesTypesList
          .map((e) => {
            return {
              title: e.label,
              path: "directory/companies/" + e.label.toLowerCase() + "/" + e.id,
              params: {
                name: e.label.toLowerCase(),
                id: e.id,
                pageTitle: e.label,
                title: e.label,
                tips: "Liste des " + e.label + "s",
              },
              functionIds: [6],
            };
          })
          .concat([
            {
              title: "Contacts",
              route: "contacts",
              functionIds: [8],
            },
          ]),
      },
    ],
  },
  {
    title: "Entreprise",
    icon: "people_alt",
    iconType: "google",
    children: [
      {
        type: "divider",
        title: "Entreprise",
        class: "mt-1",
        children: [
          {
            title: "Collaborateurs",
            route: "collaborators",
            functionIds: [5],
          },
          {
            title: "Utilisateurs",
            route: "users",
            functionIds: [89],
          },
        ],
      },
    ],
  },
];
//Il faut filtrer le tableau en ne renvoyant que les elements pour lesquels l'utilisateur a les droits ainsi que leurs parents, sinon l'utilisateur ne possède les droits sur aucun des enfants d'un element, alors on ne renvoie pas l'element
function filterMenuItems(menuItems: MenuItem[]) {
  return menuItems.filter((item) => {
    if (item.children) {
      item.children = filterMenuItems(item.children);
      return item.children.length > 0;
    } else {
      if (item.role && item.role == "Admin") {
        return tokenInfo.role && tokenInfo.role == "Admin";
      } else if (item.functionIds && item.functionIds.length > 0) {
        return item.functionIds.every(
          (functionId) =>
            (checkFunctionUser(functionId, "read") &&
              checkFunctionUser(functionId, "update") &&
              checkFunctionUser(functionId, "create") &&
              checkFunctionUser(functionId, "delete")) ||
            checkFunctionUser(functionId, "execute")
        );
      } else {
        return true;
      }
    }
  });
}

@Component
export default class MenuComponent extends Vue {
  menuItems = menuItems;
  mobileMenuItems = mobileMenuItems;
  get filteredRightMenuItems() {
    return filterMenuItems(rightMenuItems);
  }
  get filteredMobileMenuItems() {
    const items = this.mobileMenuItems.map((item) => {
      if (
        item.title === "Planning" &&
        store.getters.planningViewsList.length > 0
      ) {
        item.children = store.getters.planningViewsList
          .map((e: any) => ({
            title: e.name,
            name: "planning",
            params: {
              id: e.id,
              title: "Planning : " + e.name,
              tips: "Planning : " + e.name,
            },
            functionIds: [45, 46, 47],
          }))
          .concat([
            {
              title: "Créer un nouveau planning",
              route: "newPlanningView",
              functionIds: [45, 46, 47],
            },
          ]);
      }
      if (item.title == "Annuaire") {
        item.children = store.getters.companiesTypesList
          .map((e) => {
            return {
              title: e.label,
              path: "directory/companies/" + e.label.toLowerCase() + "/" + e.id,
              params: {
                name: e.label.toLowerCase(),
                id: e.id,
                pageTitle: e.label,
                title: e.label,
                tips: "Liste des " + e.label + "s",
              },
              functionIds: [6],
            };
          })
          .concat([
            {
              title: "Contacts",
              route: "contacts",
              functionIds: [8],
            },
          ]);
      }
      return item;
    });
    return filterMenuItems(items);
  }
  get filteredMenuItems() {
    const items = this.menuItems.map((item) => {
      if (
        item.title === "Planning" &&
        store.getters.planningViewsList.length > 0
      ) {
        item.children = store.getters.planningViewsList
          .map((e: any) => ({
            title: e.name,
            name: "planning",
            params: {
              id: e.id,
              title: "Planning : " + e.name,
              tips: "Planning : " + e.name,
            },
            functionIds: [45, 46, 47],
          }))
          .concat([
            {
              title: "Créer un nouveau planning",
              route: "newPlanningView",
              functionIds: [45, 46, 47],
            },
          ]);
      }
      if (item.title == "Annuaire") {
        const parent = item.children.find(
          (elem) => elem.title == "Annuaire" && elem.type == "divider"
        );
        if (parent) {
          parent.children = store.getters.companiesTypesList
            .map((e) => {
              return {
                title: e.label,
                path:
                  "directory/companies/" + e.label.toLowerCase() + "/" + e.id,
                params: {
                  name: e.label.toLowerCase(),
                  id: e.id,
                  pageTitle: e.label,
                  title: e.label,
                  tips: "Liste des " + e.label + "s",
                },
                functionIds: [6],
              };
            })
            .concat([
              {
                title: "Contacts",
                route: "contacts",
                functionIds: [8],
              },
            ]);
        }
      }
      return item;
    });
    return filterMenuItems(items);
  }

  created() {
    this.$watch(
      () => store.getters.planningViewsList,
      () => {
        // Trigger recalculation if planningViewsList changes
        this.$forceUpdate();
      },
      { deep: true }
    );
    this.$watch(
      () => store.getters.companiesTypesList,
      () => {
        // Trigger recalculation if companiesTypesList changes
        this.$forceUpdate();
      },
      { deep: true }
    );
  }
}

export const filteredMenuItems = filterMenuItems(menuItems);
export const filteredRightMenuItems = filterMenuItems(rightMenuItems);
export const filteredMobileMenuItems = filterMenuItems(mobileMenuItems);
