var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.canViewVerticalNavMenuLink(_vm.item) &&
    _vm.verifRole(_vm.item) &&
    _vm.item.type !== 'divider'
  )?_c('li',{staticClass:"nav-item",class:{
    disabled: _vm.item.disabled,
    'border-0': _vm.item.type,
  }},[(_vm.item.path)?_c('b-link',{staticClass:"dropdown-item",attrs:{"to":{ path: '/' + _vm.item.path, params: _vm.item.params }}},[(_vm.item.icon)?_c('feather-icon',{staticClass:"m-0 mr-50",attrs:{"icon":_vm.item.icon,"size":"16"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.t(_vm.item.title))+" ")],1):(_vm.item.name && _vm.item.params)?_c('b-link',{staticClass:"d-flex align-items-center",attrs:{"to":{ name: _vm.item.name, params: _vm.item.params }}},[(_vm.item.iconType && _vm.item.iconType == 'google')?_c('span',{staticClass:"material-icons-outlined mr-50"},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"m-0 mr-50",attrs:{"icon":_vm.item.icon,"size":"16"}}),_c('span',{staticClass:"menu-title",staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.badge)?_c('span',{staticClass:"menu-title badge font-smaller",class:'bg-' + _vm.item.badgeColor},[_vm._v(_vm._s(_vm.t(_vm.item.badge)))]):_vm._e()],1):_c('b-link',_vm._b({staticClass:"d-flex align-items-center"},'b-link',_vm.linkProps,false),[(_vm.item.iconType && _vm.item.iconType == 'google')?_c('span',{staticClass:"material-icons-outlined mr-50"},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]):_vm._e(),_c('feather-icon',{staticClass:"m-0 mr-50",attrs:{"icon":_vm.item.icon,"size":"16"}}),_c('span',{staticClass:"menu-title",staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.badge)?_c('span',{staticClass:"menu-title badge font-smaller",class:'bg-' + _vm.item.badgeColor},[_vm._v(_vm._s(_vm.t(_vm.item.badge)))]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }