<template>
  <div class="navbar-container d-flex d-md-none content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link pl-0" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Brand Logo Mobile -->
    <b-link class="d-flex align-items-center m-auto" :to="homePage">
      <span class="brand-logo">
        <!-- <b-img
          :src="appLogoFull"
          alt="logo"
          style="height: 26px; filter: brightness(0) invert(1);"
        /> -->
        <b-img
          :src="
            workspaceSelected && workspaceSelected.logo
              ? workspaceSelected.logo
              : appLogoFull
          "
          alt="logo"
          style="height: 26px; filter: brightness(0) invert(1)"
        />
      </span>
    </b-link>

    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-block" />
    </div>  -->

    <!-- User Dropdown Mobile-->
    <b-navbar-nav class="nav align-items-center ml-0">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link pr-0"
        class="dropdown-user border-right-0"
      >
        <template class="pr-0" #button-content>
          <b-avatar
            size="32"
            :src="userData ? userData.avatar : ''"
            class="rounded bg-white text-primary"
          >
            <span class="b-avatar-text">
              <div class="text-primary">
                {{
                  userData
                    ? userData.firstName.charAt(0) + userData.lastName.charAt(0)
                    : "ND"
                }}
              </div>
            </span>
          </b-avatar>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push('/account')"
        >
          <span>
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            Mon Compte
          </span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <span>
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            Déconnexion
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        v-if="institutionsList.length > 1"
        right
        toggle-class="d-flex align-items-center dropdown-user-link pr-0"
        class="dropdown-user border-right-0"
      >
        <template class="pr-0" #button-content>
          <b-avatar
            size="32"
            :src="userData ? userData.avatar : ''"
            class="
              rounded
              bg-transparent
              border-white
              badge-secondary
              text-primary
            "
          >
            <span class="b-avatar-text text-primary">
              <span class="material-icons-outlined m-0 text-primary">
                domain
              </span>
            </span>
          </b-avatar>
        </template>
        <vs-divider
          color="#cccccc"
          class="m-0 mt-1 mb-50 text-primary"
          position="left"
          v-if="institutionsList.length > 1"
        >
          <span class="text-primary">Société</span>
        </vs-divider>
        <p class="user-name">
          {{
            institutionSelected && institutionSelected.name
              ? institutionSelected.name
              : workspaceSelected.socialReason
          }}
          <span style="font-size: 0.75rem">▼</span>
        </p>
        <vs-divider
          color="#cccccc"
          class="m-0 mt-1 mb-50 text-primary"
          position="left"
          v-if="institutionsList.length > 1"
        >
          <span class="text-primary">Changer de société</span>
        </vs-divider>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-for="(institution, index) of institutionsList"
          v-show="institution.id !== institutionSelected.id"
          :key="index"
          @click="onSwitchInstitution(institution)"
        >
          <span class="d-flex justify-content-between w-100">
            {{ institution.name }}
            <feather-icon size="16" icon="ArrowRightCircleIcon" class="mr-0" />
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <div
        @click.stop="$router.push('/edit-institution/' + encryptInstitutionId)"
        v-else-if="!encryptInstitutionId == 0"
        right
        toggle-class="d-flex align-items-center dropdown-user-link pr-0"
        class="dropdown-user border-right-0"
        style="padding-left: 5px"
      >
        <!-- <template class="pr-0" #button-content> -->
        <b-avatar
          size="32"
          :src="userData ? userData.avatar : ''"
          class="rounded bg-transparent border-white badge-secondary pr-0"
        >
          <span class="b-avatar-text">
            <span class="material-icons-outlined m-0"> domain </span>
          </span>
        </b-avatar>
        <!-- </template> -->
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { $themeConfig } from "@themeConfig";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
// import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    let userData = JSON.parse(localStorage.getItem("userInfo"));
    const { appName, appLogoFull } = $themeConfig.app;
    let encryptInstitutionId = "";

    return {
      appName,
      appLogoFull,
      userData,
      encryptInstitutionId,
    };
  },
  created() {
    this.encryptInstitutionId = this.institutionSelected
      ? this.institutionSelected.id
      : 0;
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "institutionsList",
      "institutionSelected",
      "userParametersList",
    ]),
    homePage() {
      if (this.userParametersList.find((el) => el.key == "homePage")) {
        return this.userParametersList.find((el) => el.key == "homePage").value;
      } else {
        return "/";
      }
    },
  },
  methods: {
    async onSwitchInstitution(institution) {
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Attention, tous vos onglets en cours d'édition seront fermés.",
          {
            title: "Souhaitez-vous changer de société par défaut ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch("setIsGlobalLoading", true);
            await this.$tabs.reset("/");
            await this.$tabs.refreshAll();
            _this.$nextTick(async () => {
              await this.$store.dispatch("setIsGlobalLoading", false);
            });

            this.encryptInstitutionId = this.institutionSelected
              ? this.institutionSelected.id
              : 0;
          }
        });
    },
    logOut() {
      this.$store.getters.authService.logout();
    },
  },
};
</script>
