<template>
  <div
    class="w-100 d-flex justify-content-start align-items-center"
    style="font-size: 0.7rem; padding: 0.3rem 0.5rem"
  >
    <span class="mr-50">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://www.devinfluence.fr/" target="_blank"
        >DEV INFLUENCE</b-link
      >
      <span class="d-none d-sm-inline-block"
        >, Tous droits réservés - Version {{ version }}</span
      >
    </span>
    <span class="float-md-right d-none d-md-block"
      >Développé et géré par
      <a target="_blank" href="https://www.devinfluence.fr/"> DEV INFLUENCE </a>
    </span>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
var pjson = require("@/../package.json");

export default {
  components: {
    BLink,
  },
  data() {
    return {
      env: process.env.NODE_ENV,
      version: pjson.version,
    };
  },
};
</script>
