<template>
  <li
    v-if="
      canViewVerticalNavMenuLink(item) &&
      verifRole(item) &&
      item.type !== 'divider'
    "
    class="nav-item"
    :class="{
      disabled: item.disabled,
      'border-0': item.type,
    }"
  >
    <b-link
      v-if="item.path"
      :to="{ path: '/' + item.path, params: item.params }"
      class="dropdown-item"
    >
      <feather-icon
        v-if="item.icon"
        :icon="item.icon"
        class="m-0 mr-50"
        size="16"
      />
      {{ t(item.title) }}
    </b-link>

    <b-link
      v-else-if="item.name && item.params"
      :to="{ name: item.name, params: item.params }"
      class="d-flex align-items-center"
    >
      <span
        class="material-icons-outlined mr-50"
        v-if="item.iconType && item.iconType == 'google'"
      >
        {{ item.icon }}
      </span>
      <feather-icon :icon="item.icon" class="m-0 mr-50" size="16" />
      <span style="white-space: normal" class="menu-title">{{
        t(item.title)
      }}</span>
      <span
        v-if="item.badge"
        class="menu-title badge font-smaller"
        :class="'bg-' + item.badgeColor"
        >{{ t(item.badge) }}</span
      >
    </b-link>
    <b-link v-else v-bind="linkProps" class="d-flex align-items-center">
      <span
        class="material-icons-outlined mr-50"
        v-if="item.iconType && item.iconType == 'google'"
      >
        {{ item.icon }}
      </span>
      <feather-icon :icon="item.icon" class="m-0 mr-50" size="16" />
      <span style="white-space: normal" class="menu-title">{{
        t(item.title)
      }}</span>
      <span
        v-if="item.badge"
        class="menu-title badge font-smaller"
        :class="'bg-' + item.badgeColor"
        >{{ t(item.badge) }}</span
      >
    </b-link>
  </li>

  <!-- <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li> -->
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userInfo")),
    };
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
  methods: {
    verifRole(item) {
      if (
        item.role &&
        item.role == "admin" &&
        !this.userData.roles.includes("Admin")
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
