<template>
  <div class="d-flex">
    <ul id="main-menu-navigation" class="nav navbar-nav">
      <li
        class="nav-item b-nav-dropdown dropdown dropdown-user"
        :class="{
          'sidebar-group-active active open': isActiveMenuUser,
          show: isOpenMenuUser,
        }"
        @mouseenter="() => updateGroupOpenMenuUser(true)"
        @mouseleave="() => updateGroupOpenMenuUser(false)"
      >
        <b-link
          class="
            nav-link
            dropdown-user
            d-flex
            align-items-center
            dropdown-user-link
          "
        >
          <div class="d-lg-flex d-none user-nav">
            <p class="user-name">
              {{
                connectedUser
                  ? connectedUser.firstName + " " + connectedUser.lastName
                  : ""
              }}
            </p>
            <span class="user-status">{{
              connectedUser &&
              connectedUser.roles &&
              connectedUser.roles.toString() !== ""
                ? connectedUser.roles.toString()
                : "Utilisateur"
            }}</span>
          </div>
          <b-avatar
            size="32"
            :src="connectedUser ? connectedUser.avatar : ''"
            class="rounded bg-white text-primary"
          >
            <!-- badge="1"
          badge-variant="warning" 
          badge-top -->
            <span class="b-avatar-text">
              <div class="text-primary">
                {{
                  connectedUser &&
                  connectedUser.firstName &&
                  connectedUser.lastName
                    ? connectedUser.firstName.charAt(0) +
                      connectedUser.lastName.charAt(0)
                    : "ND"
                }}
              </div>
            </span>
          </b-avatar>
        </b-link>
        <ul class="dropdown-menu">
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="$router.push('/account')"
          >
            <span>
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              Mon Compte
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logOut"
          >
            <span>
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              Déconnexion
            </span>
          </b-dropdown-item>

          <!-- <vs-divider color="#cccccc" class="m-0 mt-1 mb-50" position="left" >
          <span class="text-primary">Actualités</span>
        </vs-divider>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="openSidebarCampaign()"
        >
          <span>
            <span class="material-icons-outlined text-warning mr-50">campaign</span>
            Informations ORISIS 
          </span>
        </b-dropdown-item> -->
        </ul>
      </li>
      <li
        v-if="institutionSelected"
        class="nav-item b-nav-dropdown dropdown dropdown-user"
        :class="{
          'sidebar-group-active active open': isActive,
          show: isOpen,
        }"
        @mouseenter="() => updateGroupOpen(true)"
        @mouseleave="() => updateGroupOpen(false)"
      >
        <b-link
          class="
            nav-link
            dropdown-user
            d-flex
            align-items-center
            dropdown-user-link
          "
        >
          <div class="d-sm-flex d-none user-nav mr-0">
            <span class="user-status" style="margin-top: 0.7rem">Société</span>
            <p class="user-name">
              {{
                institutionSelected && institutionSelected.name
                  ? institutionSelected.name
                  : workspaceSelected.socialReason
              }}
              <span
                style="font-size: 0.75rem"
                v-if="
                  institutionsListMinified.length > 1 ||
                  checkFunctionUser(63, 'all') ||
                  (tokenInfo.role && tokenInfo.role == 'Admin')
                "
                >▼</span
              >
            </p>
          </div>
        </b-link>
        <ul
          class="dropdown-menu"
          style="overflow-y: auto"
          v-if="
            institutionsListMinified.length > 1 ||
            checkFunctionUser(63, 'all') ||
            (tokenInfo.role && tokenInfo.role == 'Admin')
          "
        >
          <vs-divider
            color="#cccccc"
            class="m-0 mt-1 mb-50 text-primary"
            position="left"
            v-if="institutionsListMinified.length > 1"
          >
            <span class="text-primary">Changer de société</span>
          </vs-divider>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            v-for="(institution, index) of institutionsListMinified"
            v-show="institution.id !== institutionSelected.id"
            :key="index"
            @click="onSwitchInstitution(institution)"
          >
            <div class="d-flex justify-content-between w-100">
              <span style="white-space: normal">
                {{ institution.name }}
              </span>
              <feather-icon
                size="16"
                icon="ArrowRightCircleIcon"
                class="mr-0"
              />
            </div>
          </b-dropdown-item>
          <li v-if="checkFunctionUser(63, 'all')">
            <b-link
              :to="'/edit-institution/' + encryptInstitutionId"
              class="dropdown-item"
            >
              <span class="menu-title">
                <feather-icon icon="ListIcon" class="m-0 mr-50" size="16" />
                Ma société
              </span>
            </b-link>
          </li>
          <li v-if="tokenInfo.role && tokenInfo.role == 'Admin'">
            <b-link @click="newInstitutionCheck" class="dropdown-item">
              <span class="menu-title">
                <feather-icon icon="PlusIcon" class="m-0 mr-50" size="16" />
                Nouvelle société
              </span>
            </b-link>
          </li>
        </ul>
      </li>
      <li v-else-if="tokenInfo.role && tokenInfo.role == 'Admin'">
        <b-link @click="newInstitutionCheck" class="dropdown-item">
          <span class="text-white menu-title">
            <feather-icon icon="PlusIcon" class="m-0 mr-50" size="16" />
            Nouvelle société
          </span>
        </b-link>
      </li>
    </ul>
    <vue-element-loading
      background-color="rgba(24, 54, 109, 1)"
      color="#f1f1f1"
      :active="loadingSwitchWorkspace"
      :is-full-screen="true"
      :text="'Chargement des données de l\'entreprise ' + workspaceName"
    />
    <!-- <ejs-sidebar class="default-sidebar items-no-padding" v-show="isOpenSidebar" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpenSidebar">
    <sidebar-campaign-content ref="sidebarCampaign" />
  </ejs-sidebar> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { checkFunctionUser } from "@/auth/utils";
import VueElementLoading from "vue-element-loading";
import useHorizontalNavMenuHeaderGroup from "@/@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-header-group/useHorizontalNavMenuHeaderGroup";
import { InstitutionModel } from "@/types/api-orisis/models/InstitutionModel";
import { jwtDecode } from "jwt-decode";
// import SidebarCampaignContent from "@/components/parameters/SidebarCampaignContent";
// import { SidebarComponent } from '@syncfusion/ej2-vue-navigations';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VueElementLoading,
    // SidebarCampaignContent,
    // 'ejs-sidebar': SidebarComponent,
  },
  setup(props) {
    let workspaceName = "";
    let loadingSwitchWorkspace = false;
    let encryptInstitutionId = "";
    const {
      isActive,
      updateIsActive,
      isOpen,
      updateGroupOpen,

      isActiveMenuUser,
      updateIsActiveMenuUser,
      isOpenMenuUser,
      updateGroupOpenMenuUser,

      isActiveMenuAdmin,
      updateIsActiveMenuAdmin,
      isOpenMenuAdmin,
      updateGroupOpenMenuAdmin,
    } = useHorizontalNavMenuHeaderGroup(props.item);

    return {
      encryptInstitutionId,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      isOpenMenuUser,
      isActiveMenuUser,
      updateGroupOpenMenuUser,
      updateIsActiveMenuUser,

      isOpenMenuAdmin,
      isActiveMenuAdmin,
      updateGroupOpenMenuAdmin,
      updateIsActiveMenuAdmin,

      workspaceName,
      loadingSwitchWorkspace,
    };
  },
  data() {
    return {
      isOpenSidebar: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
    };
  },
  async created() {
    this.encryptInstitutionId = this.institutionSelected
      ? this.institutionSelected.id
      : 0;
  },

  methods: {
    ...mapActions(["initializeApp"]),
    checkFunctionUser,
    openSidebarCampaign() {
      this.isOpenSidebar = true;
      this.$refs.sidebar.toggle();
    },
    newInstitutionCheck() {
      if (this.verifAdmin) {
        this.$router.push({ name: "new-institution" });
      } else {
        this.$bvModal.msgBoxConfirm(
          "Veuillez nous contacter pour créer une nouvelle société.",
          {
            title: "Droit insuffisant",
            size: "sm",
            okVariant: "primary",
            okTitle: "Ok",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        );
      }
    },
    async onSwitchInstitution(institution) {
      let tabs = this.$tabs;
      let _this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Attention, tous vos onglets en cours d'édition seront fermés.",
          {
            title: "Souhaitez-vous changer de société ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch("setIsGlobalLoading", true);
            await this.$store.getters.authService.renewToken(
              null,
              institution.uniqueIdentifier
            );
            await this.initializeApp();
            // await this.$store.dispatch("getUserInfoConnected", {});
            // tabs.items.forEach((tab) => {
            //   if (tab.functionIds && tab.functionIds.length > 0) {
            //     tab.functionIds.forEach((functionId) => {
            //       if (
            //         !tab.functionIds.every(
            //           (funct) =>
            //             (checkFunctionUser(funct, "read") &&
            //               checkFunctionUser(funct, "create") &&
            //               checkFunctionUser(funct, "delete") &&
            //               checkFunctionUser(funct, "update")) ||
            //             checkFunctionUser(funct, "execute")
            //         )
            //       ) {
            //         tabs.close(tab.to);
            //       }
            //     });
            //   }
            // });
            // //Ou bien on ferme les onglets sur lesquels on a pas les droits
            // await this.$store.dispatch("getInstitution", {});
            // await this.$store.dispatch("getUserParametersByUserId", {
            //   userId: this.connectedUser.id,
            // });
            await _this.$nextTick(async () => {
              // tabs.reset();
              const homePage = this.$store.getters.userParametersList.find(
                (elem) => elem.key == "homePage"
              );
              if (homePage) {
                this.$tabs.reset(homePage.value);
              }
              else if (checkFunctionUser(134, "execute")) {
                this.$tabs.reset("/home");
              }
              else if (checkFunctionUser(135, "execute")) {
                this.$tabs.reset("/dashboard-gestion");
              }
              else if (checkFunctionUser(136, "execute")) {
                this.$tabs.reset("/dashboard-crm");
              }
              else if (checkFunctionUser(137, "execute")) {
                this.$tabs.reset("/dashboard-immo");
              }
              else if (checkFunctionUser(1, "read")) {
                this.$tabs.reset("/affairs");
              }
              else {
                this.$tabs.reset("/my-agenda");
              }
              await this.$store.dispatch("setIsGlobalLoading", false);
            });

            this.encryptInstitutionId = this.institutionSelected
              ? this.institutionSelected.id
              : 0;
          }
        });
    },
    logOut() {
      this.$store.getters.authService.logout();
      // this.$store
      //   .dispatch("logOut")
      //   .then(() => {
      //     this.$tabs.reset("/");
      //     this.$router.push("/login");
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     this.$tabs.reset("/");
      //     this.$router.push("/login");
      //   });
    },
  },
  computed: {
    ...mapGetters([
      "institutionsList",
      "institutionsListMinified",
      "isLoadingworkspacesListByUser",
      "connectedUser",
      "workspaceSelected",
      "institutionSelected",
    ]),
    tokenInfo() {
      return jwtDecode(localStorage.getItem("userToken"));
    },
    workspacesListByUserLocal() {
      let info = JSON.parse(localStorage.getItem("userInfo"));
      return info.workspaces;
    },
    verifAdmin() {
      // CORRECTIONS AVEC FONCTIONS
      if (this.userInfo && this.userInfo.roles.includes("Admin")) {
        return true;
      } else {
        return true;
        return false;
      }
    },
  },
};
</script>
